import React, {useEffect, useMemo, useState} from 'react';
import {EditorState, convertToRaw, convertFromHTML, ContentState, Modifier} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import '../assets/css/react-draft.css'
import {Col, Row} from "react-bootstrap";
import DropdownItemsInput from "./DropdownItemsInput";
import {useSelector} from "react-redux";
import htmlToDraft from "html-to-draftjs";
import {Map} from "immutable";
import DefaultDraftBlockRenderMap from "draft-js/lib/DefaultDraftBlockRenderMap";

const blockRenderMap = Map({
    "unstyled": {
        element: "div"
    }
});
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

function WyEditor(props) {
    const [dynamicValue, setDynamicValue] = useState([])

    const [editorState, setEditorState] = useState(
        () => EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(props.para == undefined ? "" : `${props.para}`)
            )
        ),
    );

    const getDynamicList = useSelector(state => state.getDynamic)
    const {dynamic_list} = getDynamicList

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevents scrolling behavior
            setEditorState(EditorState.createWithContent(editorState.getCurrentContent())); // Preserve content
        }
    };

    useEffect(() => {
        if (!getDynamicList.dynamic_value <= 0) {
            getDynamicList.dynamic_value.map((item, index) => {
                setDynamicValue((prevData) => [
                    ...prevData,
                    {
                        id: index,
                        data: `{{${item}}}`
                    }
                ])
            });
        }
    }, [getDynamicList.dynamic_value]);


    const htmlData = useMemo(
        () => draftToHtml(convertToRaw(editorState.getCurrentContent()))
            .replace(/<p>/g, "<div>")
            .replace(/<\/p>/g, "</div>"), // Convert <p> to <div>
        [editorState]
    );


    const getEditorContent = () => {
        const contentState = editorState.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        console.log(rawContent);
    };

    const handleChange = (data) => {
        setEditorState(data);
    }
    const handleDropdownChange = (value) => {
        // Modify the editor state to insert the selected value
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        const newContentState = Modifier.replaceText(
            contentState,
            selectionState,
            value.data,
        );
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            'insert-characters',
        );
        setEditorState(newEditorState);
    };

    useEffect(() => {
        props.result([draftToHtml(convertToRaw(editorState.getCurrentContent())).toString(), htmlData])
        console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);

    useEffect(() => {
        if (props.para) {
            const sanitizedHtml = props.para.replace(/<div([^>]*)>\s*<\/div>/g, '<div$1>&nbsp;</div>');
            const blocksFromHtml = htmlToDraft(props.para);
            if (blocksFromHtml) {
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                setEditorState(EditorState.createWithContent(contentState));
            }
        }
    }, [props.para]);

    return (
        <>
            {props.dynamicValue && <Row className="background-1">

                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-left-unset">
                </Col>
                <Col lg={{span: 6, offset: 0}} md={{span: 6, offset: 0}}
                     className="padding-right-unset">
                    <div className="document-form">
                        <label htmlFor="document_title" id="certificateNameLabel"
                               className="form-label form-spacing">
                            Dynamic Values<span className="asterics"></span></label>
                        <div>
                            <DropdownItemsInput data={dynamicValue}
                                                result={handleDropdownChange}
                                                className="background-1 background-1 dropdown-input-content"
                                                title="Select Dynamic Value"/>
                        </div>
                    </div>
                </Col>
            </Row>}
            {/*<CustomToolbar />*/}
            <Editor
                placeholder="Enter Document Text"
                editorState={editorState}
                blockRenderMap={extendedBlockRenderMap}
                onEditorStateChange={handleChange}
                onKeyDown={handleKeyDown}
                toolbar={{
                    inline: {inDropdown: false},
                    list: {inDropdown: false},
                    textAlign: {inDropdown: true},
                    link: {inDropdown: false},
                    history: {inDropdown: false},
                    image: {
                        previewImage: true,
                        uploadCallback: (file) => {
                            return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    resolve({
                                        data: {
                                            url: reader.result,
                                            alignment: "left"
                                        },
                                    });
                                };

                                reader.onerror = (reason) => reject(reason);
                                reader.readAsDataURL(file);
                            });
                        },
                        defaultSize: { height: "100%", width: "100%" },
                        alignmentEnabled: true,
                        alt: {present: true, mandatory: false},
                    },
                }}
                // wrapperStyle={{ height: "100%" }}
                // editorStyle={{ height: "100%", overflow: "auto" }}
                wrapperClassName={draftToHtml(convertToRaw(editorState.getCurrentContent())).toString().length > 10 ? "wrapper-editor" : "wrapper-editor-error"}
            />
            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}*/}
            {/*/>*/}
        </>
    );
}

export default WyEditor;
