import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from 'react-bootstrap'
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import {useLocation, useNavigate} from "react-router-dom";
import letter_preview from "../assets/json/letter_preview_list.json";
import Footer from "../components/Footer";
import Send from "../components/Icons/Send";
import {getTemplateColumns, resetSendLettersNotices, sendLettersNotices} from "../actions/LetterNoticesActions";
import HTMLViewer from "../components/HTMLViewer";
import Loader from "../components/Loader";
import AlertModal from "../components/AlertModal";
import {getTeacherAssociates} from "../actions/ConfigActions";
import AddCircle from "../components/Icons/AddCircle";

function LettersPreview(props) {
    const idCounter = useRef(0);
    const history = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const [inputs, setInputs] = useState([]);
    const [inputsEmail, setInputsEmail] = useState([]);
    const [teacher, setTeacher] = useState([])
    const [years, setYears] = useState([]);
    const [concatColumns, setConcatColumns] = useState([]);
    const [combinedColumns, setCombinedColumns] = useState([]);
    const [sentToList, setSentToList] = useState([]);
    const [sentToListEmail, setSentToListEmail] = useState([]);
    const [templateColumn, setTemplateColumn] = useState({
        letterTemplateId: "",
        title: "",
        descriptions: "",
        manualDynamicColumns: "",
    });
    const [alertModal, setAlertModal] = useState({
        display: false,
        type: "",
        message: ""
    });
    const [templateColumnValues, setTemplateColumnValues] = useState([])
    const [templateColumnValuesEmail, setTemplateColumnValuesEmail] = useState([])
    const [submit, setSubmit] = useState(true);
    const [submitEmail, setSubmitEmail] = useState(true);
    const [dynamicColumns, setDynamicColumns] = useState(false);
    const [dynamicColumnsEmail, setDynamicColumnsEmail] = useState(false);
    const pageName = 'letters';

    const getTeacherList = useSelector(state => state.getTeacherAssociate)
    const {teacher_list} = getTeacherList

    useEffect(() => {
        if (!getTeacherList.teacher_list <= 0) {
            setTeacher([])
            // console.log(getTeacherList.teacher_list)
            getTeacherList.teacher_list.map((item, index) => {
                setTeacher((prevData) => [
                    ...prevData,
                    {
                        id: item.employee_ID,
                        data: `${item.employee_First_Name} ${item.employee_Last_Name}`,
                    }
                ])
            });
        }
    }, [getTeacherList.teacher_list]);
    const openLink = () => {
        history('/send-letters-notices')
    }
    const gotoLetters = () => {
        console.log("sentToList : ", sentToList)
        console.log("sentToListEmail : ", sentToListEmail)
        dispatch(sendLettersNotices(location.state?.templateId, location.state?.templateTextRaw, sentToList, sentToListEmail))
    }

    const sendLettersNoticesDetails = useSelector(state => state.sendLettersNotices)
    const {errorSend, loadingSend, letters_notices} = sendLettersNoticesDetails

    useEffect(() => {
        if (sendLettersNoticesDetails.letters_notices === true) {
            dispatch(resetSendLettersNotices())
            history('/letters-notices', {
                state: {
                    name: "1",
                    "message": `Letters Sent Successfully to ${(location.state?.userList.length + location.state?.emails.length - location.state?.emailCheck.length)}/${location.state?.userList.length + location.state?.emails.length} teachers/associates`
                }
            })
        }
        if (sendLettersNoticesDetails.error) {
            setAlertModal({
                display: true,
                type: error,
                message: "Letters/Notices couldn't be added"
            })
        }
    }, [sendLettersNoticesDetails]);

    useEffect(() => {
        dispatch(getTeacherAssociates())
        console.log(location.state?.emails)
        if (location.state?.emails.length > 0)
            setDynamicColumnsEmail(true)
        letter_preview.map((item, index) => {
            setYears(prevState => [
                ...prevState,
                item.year
            ])
        });
        dispatch(getTemplateColumns(location.state?.templateId))
    }, []);

    const getTemplateColumnDetails = useSelector(state => state.getTemplateColumn)
    const {error, loading, template_column} = getTemplateColumnDetails

    useEffect(() => {
        setTemplateColumn([])
        if (!getTemplateColumnDetails.template_column <= 0) {
            setTemplateColumn({
                ...templateColumn,
                letterTemplateId: getTemplateColumnDetails.template_column.letterTemplateId,
                title: getTemplateColumnDetails.template_column.title,
                descriptions: getTemplateColumnDetails.template_column.descriptions,
                manualDynamicColumns: getTemplateColumnDetails.template_column.manualDynamicColumns || "",
                prismDynamicColumns: getTemplateColumnDetails.template_column.prismDynamicColumns || "",
            })
        }
    }, [getTemplateColumnDetails.template_column]);

    useEffect(() => {
        setTemplateColumnValues([])
        setSentToList([])
        if (location.state?.userList.length === 0) {
            setSubmit(true)
        } else {
            if ((templateColumn.prismDynamicColumns === "" || templateColumn.prismDynamicColumns === undefined) && (templateColumn.manualDynamicColumns === "" || templateColumn.manualDynamicColumns === undefined)) {
                console.log(templateColumn.manualDynamicColumns)
                setSubmitEmail(true)
                setSubmit(true)
            } else {
                setSubmit(false)
            }
        }
        const arr = location.state?.userList.toString().split(',')
        if (!templateColumn.manualDynamicColumns <= 0 || templateColumn.manualDynamicColumns !== undefined) {
            arr.map((item, index) => {
                if (templateColumn.manualDynamicColumns.length == 0) {
                    setDynamicColumns(false)
                    setSubmit(true)
                } else {
                    setDynamicColumns(true)
                }

                teacher.map(value => {
                    if (value.id === item) {
                        setTemplateColumnValues(prevState => [
                            ...prevState, {
                                teacherId: value.id,
                                teacherName: value.data
                            }
                        ])
                        addSentTo(value.id)
                    }
                })

            })
        }
    }, [templateColumn.manualDynamicColumns, templateColumn.prismDynamicColumns]);

    useEffect(() => {
        setTemplateColumnValuesEmail([])
        setSentToListEmail([])
        if (location.state?.emails.length === 0) {
            setSubmitEmail(true)
        } else if ((templateColumn.manualDynamicColumns <= 0 || templateColumn.manualDynamicColumns === undefined) && (templateColumn.prismDynamicColumns <= 0 || templateColumn.prismDynamicColumns === undefined)) {
            console.log("Dynamic", templateColumn.manualDynamicColumns)
            console.log("Prism", templateColumn.prismDynamicColumns)
            setSubmitEmail(true)
            setSubmit(true)
        } else if ((!templateColumn.manualDynamicColumns <= 0 || templateColumn.manualDynamicColumns !== undefined) || (!templateColumn.prismDynamicColumns <= 0 || templateColumn.prismDynamicColumns !== undefined)) {
            setSubmitEmail(false)
        }
        location.state?.emails.forEach((value) => {
            setTemplateColumnValuesEmail(prevState => [
                ...prevState, {
                    email: value,
                }
            ])
            addSentToEmail(value)
        })
    }, [templateColumn.manualDynamicColumns, templateColumn.prismDynamicColumns]);

    const addSentTo = (sentToId) => {
        const newEntry = {
            sentToId: sentToId,
            dynamicColumns: templateColumn.manualDynamicColumns.map(column => ({
                columnName: column.columnName,
                columnValue: column.columnValue
            })) // Default values
        };
        setSentToList(prev => [...prev, newEntry]);
        templateColumn.manualDynamicColumns.map((data, index) => {
            const newInputs = {...inputs, [sentToId + data.columnName]: ""};
            setInputs(newInputs);
        })
    };

    const addSentToEmail = (sentToId) => {
        if (templateColumn.manualDynamicColumns && templateColumn.manualDynamicColumns !== "") {
            setConcatColumns(templateColumn.prismDynamicColumns.concat(templateColumn.manualDynamicColumns))
            const combined = [
                ...templateColumn.prismDynamicColumns,
                ...templateColumn.manualDynamicColumns
            ]
            setCombinedColumns([
                ...templateColumn.prismDynamicColumns,
                ...templateColumn.manualDynamicColumns
            ]);

            const isDuplicate = sentToListEmail.some(entry => entry.sentToEmail === sentToId);
            if (!isDuplicate) {
                const newEntry = {
                    sentToEmail: sentToId,
                    dynamicColumns: combined.map(column => ({
                        columnName: column.columnName,
                        columnValue: column.columnValue
                    }))
                };

                setSentToListEmail(prev => [...prev, newEntry]);

                combined.forEach((data) => {
                    const newInputs = {...inputsEmail, [sentToId + data.columnName]: ""};
                    setInputsEmail(newInputs);
                });
            }
        }

        // if (templateColumn.prismDynamicColumns && templateColumn.prismDynamicColumns !== "") {
        //     setConcatColumns(templateColumn.prismDynamicColumns.concat(templateColumn.manualDynamicColumns))
        //     const newEntry = {
        //         sentToEmail: sentToId,
        //         dynamicColumns: [
        //             ...templateColumn.prismDynamicColumns.map(column => ({
        //                 columnName: column.columnName,
        //                 columnValue: column.columnValue
        //             }))]
        //     };
        //     setSentToListEmail(prev => [...prev, newEntry]);
        //     templateColumn.prismDynamicColumns.map((data, index) => {
        //         const newInputs = {...inputsEmail, [sentToId + data.columnName]: ""};
        //         setInputsEmail(newInputs);
        //     })
        // }
    };

    const handleInputChange = (teacher, event, columnName) => {
        const newInputs = {...inputs, [teacher + columnName]: event.target.value};
        setInputs(newInputs);

        const updatedList = sentToList.map(task => {
            if (task.sentToId === teacher) {
                const updatedColumns = task.dynamicColumns.map(column => {
                    if (column.columnName.toString() === columnName.toString()) {
                        return {...column, columnValue: event.target.value};
                    }
                    return column;
                });
                return {...task, dynamicColumns: updatedColumns};
            }
            return task;
        });
        setSentToList(updatedList);

        // Check if all input fields are filled
        const allFieldsFilled = Object.values(newInputs).every(value => value.trim() !== '');
        if (allFieldsFilled) {
            setSubmit(true)
        } else {
            setSubmit(false)
        }
    };


    const handleInputChangeEmail = (email, event, columnName) => {
        // Update inputsEmail state
        const newInputs = {...inputsEmail, [email + columnName]: event.target.value};
        setInputsEmail(newInputs);

        // Update sentToListEmail with the modified dynamicColumns
        const updatedList = sentToListEmail.map(task => {
            if (task.sentToEmail === email) {
                const updatedColumns = task.dynamicColumns.map(column => {
                    if (column.columnName === columnName) {
                        return {...column, columnValue: event.target.value};
                    }
                    return column;
                });
                return {...task, dynamicColumns: updatedColumns};
            }
            return task;
        });
        setSentToListEmail(updatedList);

        // Check if all fields in inputsEmail are filled
        const allFieldsFilled = Object.values(newInputs).every(value => value.trim() !== '');
        setSubmitEmail(allFieldsFilled);
    }

    const showHeaders = (data) => {
        if (data) {
            return (
                <>
                    <div className="cell">Full Name</div>
                    {
                        data.map((item, index) => {
                            try {
                                return (
                                    <div className="cell">{item.columnName} </div>
                                )
                            } catch (e) {
                                alert(e.message);
                            }
                        })
                    }
                </>
            )
        }
    }
    const showHeadersEmail = (data) => {
        if (data) {
            return (
                <>
                    <div className="cell">Email</div>
                    {/*<div className="cell">Name</div>*/}
                    {
                        data.map((item, index) => {
                            try {
                                return (
                                    <div className="cell">{item.columnName} </div>
                                )
                            } catch (e) {
                                alert(e.message);
                            }
                        })
                    }
                </>
            )
        }
    }

    const showUsers = (data) => {
        if (data) {
            return data.map((item, index) => {
                try {
                    return (
                        <React.Fragment key={index}>
                            <div key={index} className="preview-row background-1">
                                <div className="cell-row">{item.teacherName}</div>
                                <>
                                    {templateColumn.manualDynamicColumns.map((data, index) => {
                                        const inputKey = item.teacherId + data.columnName;

                                        return (
                                            <div key={inputKey} className="cell-row">
                                                <input
                                                    defaultValue=""
                                                    onChange={(event) => handleInputChange(item.teacherId, event, data.columnName)}
                                                    className={"preview-table-body-input"}
                                                />
                                            </div>
                                        );
                                    })}
                                </>
                            </div>
                        </React.Fragment>
                    );
                } catch (e) {
                    console.error(e); // Log any potential errors
                }
            });
        }
    };

    const showEmails = (data) => {
        if (data) {
            return data.map((item, index) => {
                try {
                    return (
                        <React.Fragment key={index}>
                            <div key={index} className="preview-row background-1">
                                <div className="cell-row">{item.email}</div>
                                {/*<div key={index} className="cell-row">*/}
                                {/*    <input*/}
                                {/*        defaultValue=""*/}
                                {/*        onChange={(event) => handleInputChangeEmail(item.email, event, "First Name")}*/}
                                {/*        className={"preview-table-body-input"}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <>
                                    {combinedColumns.map((data, index) => {
                                        const inputKey = item.email;

                                        return (
                                            <div key={inputKey} className="cell-row">
                                                <input
                                                    defaultValue=""
                                                    onChange={(event) => handleInputChangeEmail(item.email, event, data.columnName)}
                                                    className={"preview-table-body-input"}
                                                />
                                            </div>
                                        );
                                    })}
                                </>
                            </div>
                        </React.Fragment>
                    );
                } catch (e) {
                    console.error(e); // Log any potential errors
                }
            });
        }
    };
    const showUsersOnly = (data) => {
        if (data) {
            return data.map((item, index) => {
                try {
                    return (
                        <React.Fragment key={index}>
                            <div key={index} className="preview-row background-1">
                                <div className="cell-row">{item.teacherName} </div>
                            </div>
                        </React.Fragment>
                    )
                } catch (e) {

                }
            })
        }
    }

    // const addSentToEmail = (sentToId) => {
    //     const newEntry = {
    //         sentToId: sentToId,
    //         dynamicColumns: templateColumn.manualDynamicColumns.map(column => ({
    //             columnName: column.columnName,
    //             columnValue: column.columnValue
    //         })) // Default values
    //     };
    //     setSentToList(prev => [...prev, newEntry]);
    //     templateColumn.manualDynamicColumns.map((data, index) => {
    //         const newInputs = {...inputs, [sentToId + data.columnName]: ""};
    //         setInputs(newInputs);
    //     })
    // };
    const closeAlert = (data) => {
        setAlertModal({
            display: false
        })
    }

    return (
        <>
            <Row className="sidebar-background">
                {loading && <Loader/>}
                {loadingSend && <Loader/>}
                {alertModal.display &&
                    <AlertModal heading={alertModal.message} type={alertModal.type}
                                result={closeAlert}/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="send-letters" title="Letters Preview" result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <Col lg={{span: 12}} md={{span: 12}}>
                            <div className="card-body-letters-preview mb-5">
                                <p className="notice-preview-heading">
                                    {templateColumn.title}
                                </p>
                                <div className="notice-preview-body">
                                    <div className="mb-3">
                                        <HTMLViewer htmlContent={location.state?.templateTextRaw}/>
                                    </div>
                                    {dynamicColumns && location.state?.userList.length > 0 && <div>
                                        {(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                            <Row className="preview-table">
                                                <div className="background-1 preview-table-header">
                                                    {showHeaders(templateColumn.manualDynamicColumns)}
                                                </div>
                                                {(templateColumnValues !== {} || templateColumnValues !== undefined) &&
                                                    <div className="background-1 preview-table-body">
                                                        {showUsers(templateColumnValues)}
                                                    </div>}
                                            </Row>}
                                    </div>}
                                    {!dynamicColumns && location.state?.userList.length > 0 && <div>
                                        {(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                            <Row className="preview-table">
                                                <div className="background-1 preview-table-header">
                                                    {showHeaders(templateColumn.manualDynamicColumns)}
                                                </div>
                                            </Row>
                                        }{(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                        <Row className="preview-table">
                                            <div className="background-1 preview-table-body">
                                                {showUsersOnly(templateColumnValues)}
                                            </div>
                                        </Row>
                                    }
                                    </div>}
                                    {dynamicColumnsEmail && location.state?.emails.length > 0 && <div className="mt-5">
                                        {((!templateColumn.manualDynamicColumns <= 0 || templateColumn.manualDynamicColumns !== undefined) || (!templateColumn.prismDynamicColumns <= 0 || templateColumn.prismDynamicColumns !== undefined)) &&
                                            <Row className="preview-table">
                                                <div className="background-1 preview-table-header">
                                                    {showHeadersEmail(concatColumns)}
                                                </div>
                                                {(templateColumnValues !== {} || templateColumnValues !== undefined) &&
                                                    <div className="background-1 preview-table-body">
                                                        {showEmails(templateColumnValuesEmail)}
                                                    </div>}
                                            </Row>}
                                        {/*{((templateColumn.manualDynamicColumns <= 0 || templateColumn.manualDynamicColumns === undefined) && (templateColumn.prismDynamicColumns <= 0 || templateColumn.prismDynamicColumns === undefined)) &&*/}
                                        {/*    <Row className="preview-table">*/}
                                        {/*        <div className="background-1 preview-table-header">*/}
                                        {/*            {showHeadersEmail(concatColumns)}*/}
                                        {/*        </div>*/}
                                        {/*    </Row>}*/}
                                        {/*{((templateColumn.manualDynamicColumns <= 0 || templateColumn.manualDynamicColumns === undefined) && (templateColumn.prismDynamicColumns <= 0 || templateColumn.prismDynamicColumns === undefined)) &&*/}
                                        {/*    <Row className="preview-table">*/}
                                        {/*        <div className="background-1 preview-table-body">*/}
                                        {/*            {showEmails(templateColumnValuesEmail)}*/}
                                        {/*        </div>*/}
                                        {/*    </Row>}*/}
                                    </div>}
                                </div>
                                <Row className="mt-4 button-size-25 background-1" style={{width: "100%"}}>
                                    <Col lg={{span: 9}} md={{span: 9}}/>
                                    <Col lg={{span: 3}} md={{span: 3}} className="padding-right-unset">
                                        {(!submit || !submitEmail) && <div className="button-solid button-inactive">
                                            <Send classname="sidebar-icon" color="var(--white)"/> Send Letters
                                        </div>}
                                        {submit && submitEmail &&
                                            <div className="button-solid button-active" onClick={gotoLetters}>
                                                <Send classname="sidebar-icon" color="var(--white)"/> Send Letters
                                            </div>}
                                    </Col>
                                </Row>

                            </div>
                            <Footer/>
                        </Col>
                    </Row>
                </div>
            </Row>
        </>
    )
        ;
}

export default LettersPreview;
