import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from 'react-bootstrap'
import Sidebar from "../components/Sidebar";
import Navigation from "../components/Navigation";
import Heading from "../components/Heading";
import {useNavigate, useLocation} from "react-router-dom";
import Footer from "../components/Footer";
import Calendar from "../components/Icons/Calendar";
import {Tooltip} from 'react-tooltip'
import "../assets/css/react-tooltip.css"
import InfoCircle from '../assets/images/info_circle.svg'
import HTMLViewer from "../components/HTMLViewer";
import {getLettersNoticesById, getTemplateColumns} from "../actions/LetterNoticesActions";
import Loader from "../components/Loader";
import {getTeacherAssociates} from "../actions/ConfigActions";
import Eye from "../components/Icons/Eye";
import EditorModal from "../components/EditorModal";

function LettersAcknowledgement(props) {
    const history = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const pageName = 'letters';
    const [templateColumn, setTemplateColumn] = useState({
        letterTemplateId: "",
        title: "",
        descriptions: "",
        manualDynamicColumns: "",
    });
    const [noticeValues, setNoticeValues] = useState([])
    const [noticeValuesEmail, setNoticeValuesEmail] = useState([])
    const [noticeValuesFail, setNoticeValuesFail] = useState([])
    const [dynamicColumns, setDynamicColumns] = useState(false);
    const [prismColumns, setPrismColumns] = useState(false);
    const [combinedColumns, setCombinedColumns] = useState([]);
    const [showEditor, setShowEditor] = useState({
        display: false,
        value: null
    });
    const openLink = () => {
        history('/letters-notices')
    }
    useEffect(() => {
        dispatch(getTeacherAssociates())
        dispatch(getTemplateColumns(location.state?.letterTemplateId))
        dispatch(getLettersNoticesById(location.state?.letterNoticeId))
    }, []);

    const getTemplateColumnDetails = useSelector(state => state.getTemplateColumn)
    const {error, loading, template_column} = getTemplateColumnDetails

    useEffect(() => {
        setTemplateColumn([])
        if (!getTemplateColumnDetails.template_column <= 0) {
            setTemplateColumn({
                ...templateColumn,
                letterTemplateId: getTemplateColumnDetails.template_column.letterTemplateId,
                title: getTemplateColumnDetails.template_column.title,
                descriptions: getTemplateColumnDetails.template_column.descriptions,
                manualDynamicColumns: getTemplateColumnDetails.template_column.manualDynamicColumns,
                prismDynamicColumns: getTemplateColumnDetails.template_column.prismDynamicColumns,
            })
        }
    }, [getTemplateColumnDetails.template_column]);

    const getLettersNoticesByIdDetails = useSelector(state => state.getLettersNoticesById)
    const {letters_notices_data} = getLettersNoticesByIdDetails

    useEffect(() => {
        setNoticeValues([])
        setNoticeValuesEmail([])
        setNoticeValuesFail([])
        if (!getLettersNoticesByIdDetails.letters_notices_data <= 0) {
            if (!getLettersNoticesByIdDetails.letters_notices_data.letterNoticesAssociates <= 0) {
                getLettersNoticesByIdDetails.letters_notices_data.letterNoticesAssociates.map((item, index) => {
                    if ((item.isSent == true || item.isSent == null) && item.isEmailOnly == null) {
                        setNoticeValues((prevData) => [
                            ...prevData,
                            {
                                sentToId: item.sentToId,
                                sentToName: item.sentToName != null ? item.sentToName : item.ltAssociateDynamicColumns[0]?.columnValue,
                                documentText: item.documentText,
                                columnData: item.ltAssociateDynamicColumns,
                            }
                        ])
                    } else if (item.isEmailOnly != null) {
                        setNoticeValuesEmail((prevData) => [
                            ...prevData,
                            {
                                sentToId: item.sentToId,
                                sentToName: item.sentToEmail,
                                documentText: item.documentText,
                                columnData: item.ltAssociateDynamicColumns,
                            }
                        ])
                    } else if (item.isSent == false) {
                        setNoticeValuesFail((prevData) => [
                            ...prevData,
                            {
                                sentToId: item.sentToId,
                                sentToName: item.sentToName != null ? item.sentToName : item.ltAssociateDynamicColumns[0]?.columnValue,
                                documentText: item.documentText,
                                columnData: item.ltAssociateDynamicColumns,
                            }
                        ])
                    }
                })
            }
        }
    }, [getLettersNoticesByIdDetails.letters_notices_data]);

    useEffect(() => {
        if (!templateColumn.manualDynamicColumns <= 0 || templateColumn.manualDynamicColumns !== undefined) {
            if (templateColumn.manualDynamicColumns.length == 0) {
                setDynamicColumns(false)
            } else {
                setCombinedColumns([
                    ...templateColumn.prismDynamicColumns,
                    ...templateColumn.manualDynamicColumns
                ]);
                setDynamicColumns(true)
            }
        }
    }, [templateColumn.manualDynamicColumns]);

    useEffect(() => {
        if (!templateColumn.prismDynamicColumns <= 0 || templateColumn.prismDynamicColumns !== undefined) {
            if (templateColumn.prismDynamicColumns.length == 0) {
                setPrismColumns(false)
            } else {
                setPrismColumns(true)
            }
        }
    }, [templateColumn.prismDynamicColumns]);

    const showUsersFail = (data) => {
        return data.map((item, index) => {
            try {
                return (
                    <React.Fragment key={index}>
                        <div key={index} className="preview-row background-1">
                            <div className="cell-row">{item.sentToName} </div>
                            <>
                                {
                                    templateColumn.manualDynamicColumns.map((data, index) => {
                                        return (
                                            <div key={item.sentToNameId} className="cell-row">
                                                <input
                                                    value={searchColumnValue(data.columnName, item.sentToId, item)}
                                                    readOnly={true}
                                                    className={"preview-table-body-input-no-border"}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </>
                            <div className="row background-1">
                                <div className="cell-row">
                                    <Eye className="sidebar-icon"
                                         color="var(--primary-100)"/>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            } catch (e) {
                // alert(e.message);
            }
        })
    }

    const formatDateName = (data) => {
        if (data === null) {
            return ("N/A")
        } else {
            const date = new Date(data);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
        }
    };

    const showHeaders = (data) => {
        if (data) {
            return (
                <>
                    <div className="cell">Name</div>
                    {
                        data.map((item, index) => {
                            try {
                                return (
                                    <div className="cell">{item.columnName} </div>
                                )
                            } catch (e) {
                                alert(e.message);
                            }
                        })
                    }
                    <div className="cell">Preview</div>
                </>
            )
        }
    }

    const showHeadersEmail = (data) => {
        if (data) {
            return (
                <>
                    <div className="cell">Email</div>
                    {
                        data.map((item, index) => {
                            try {
                                return (
                                    <div className="cell">{item.columnName} </div>
                                )
                            } catch (e) {
                                alert(e.message);
                            }
                        })
                    }
                    <div className="cell">Preview</div>
                </>
            )
        }
    }
    const showUsers = (data) => {
        if (data) {
            return data.map((item, index) => {
                try {
                    return (
                        <React.Fragment key={index}>
                            <div key={index} className="preview-row background-1">
                                <div className="cell-row">{item.sentToName} </div>
                                <>
                                    {
                                        templateColumn.manualDynamicColumns.map((data, index) => {
                                            return (
                                                <div key={item.sentToNameId} className="cell-row">
                                                    <input
                                                        value={searchColumnValue(data.columnName, item.sentToId, item)}
                                                        readOnly={true}
                                                        className={"preview-table-body-input-no-border"}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </>
                                <div className="row background-1">
                                    <div className="cell-row" onClick={() => showPreview(item.documentText)}>
                                        <Eye className="sidebar-icon"
                                             color="var(--primary-100)"/>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                } catch (e) {

                }
            })
        }
    }
    const showUsersEmail = (data) => {
        if (data) {
            return data.map((item, index) => {
                try {
                    return (
                        <React.Fragment key={index}>
                            <div key={index} className="preview-row background-1">
                                <div className="cell-row">{item.sentToName} </div>
                                <>
                                    {
                                        combinedColumns.map((data, index) => {
                                            return (
                                                <div key={item.sentToNameId} className="cell-row">
                                                    <input
                                                        value={searchColumnValue(data.columnName, item.sentToId, item)}
                                                        readOnly={true}
                                                        className={"preview-table-body-input-no-border"}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </>
                                <div className="row background-1">
                                    <div className="cell-row" onClick={() => showPreview(item.documentText)}>
                                        <Eye className="sidebar-icon"
                                             color="var(--primary-100)"/>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                } catch (e) {

                }
            })
        }
    }
    const showUsersOnly = (data) => {
        if (data) {
            return data.map((item, index) => {
                try {
                    return (
                        <React.Fragment key={index}>
                            <div key={index} className="preview-row background-1">
                                <div className="cell-row">{item.sentToName} </div>
                                <div className="row background-1">
                                    <div className="cell-row" onClick={() => showPreview(item.documentText)}>
                                        <Eye className="sidebar-icon"
                                             color="var(--primary-100)"/>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                } catch (e) {

                }
            })
        }
    }

    const searchColumnValue = (columnName, sentToId, data) => {
        const foundItem = data.columnData.find(item => {
            return item.columnName === columnName && data.sentToId === sentToId;
        });
        return foundItem ? foundItem.columnValue : null;
    };

    const showPreview = (data) => {
        // console.log(data)
        setShowEditor({
            display: true,
            data: data,
        })
    };

    const closeAlert = (data) => {
        setShowEditor({
            display: false
        })
    };


    return (
        <>
            <Row className="sidebar-background">
                {loading && <Loader/>}
                {showEditor.display && <EditorModal document={showEditor.data} heading={"Preview"}
                            result={closeAlert}/>}
                <div className="float-left-child sidebar app-spacing">
                    <Sidebar pageDetails={pageName}/>
                </div>
                <div className="float-right-child app-background app-spacing">
                    <Navigation/>
                    <Heading group="doc_add" title={location.state?.title} result={openLink}/>
                    <Row className="app-body-add-document background-1">
                        <div className="mb-4 mt-4">
                            <Row className="background-1 preview-table-description">
                                <div className="description-div padding-left-unset padding-right-unset">
                                    <p className="description-heading">
                                        Description
                                    </p>
                                    <p className="description-data">
                                        <HTMLViewer htmlContent={location.state?.documentText}/>
                                    </p>
                                </div>
                                <Row className="padding-left-unset padding-right-unset mt-4 background-1">
                                    <Col lg={{span: 4}} md={{span: 4}} className="padding-left-unset">
                                        <p className="description-heading">
                                            Date
                                        </p>
                                        <p className="description-data">
                                            <Calendar className="sidebar-icon"
                                                      color="var(--primary-100)"/> <span
                                            className="">{formatDateName(location.state?.createdDate)}</span>
                                        </p>
                                    </Col>
                                    <Col lg={{span: 4}} md={{span: 4}} className="padding-left-unset">
                                        <p className="description-heading">
                                            Hard Copy
                                        </p>
                                        <p className="description-data">
                                            {location.state?.isHardcopyRequired == true ? 'Attached' : 'Not-attached'}
                                        </p>
                                    </Col>
                                    <Col lg={{span: 4}} md={{span: 4}} className="padding-left-unset">
                                        <p className="description-heading">
                                            Email Status
                                        </p>
                                        <p className="description-data">
                                            Sent
                                        </p>
                                    </Col>

                                </Row>
                            </Row>
                        </div>
                        {noticeValuesFail.length > 0 && <Row className="background-1">
                            <div className="email-information">
                                <span className="description-data">Email Status :  </span>
                                <span className="description-data description-data-red">Not Sent  </span>
                                <img src={InfoCircle} data-tooltip-id="tooltip-1" className="sidebar-icon-form"/>
                                <Tooltip
                                    id="tooltip-1"
                                    place="bottom"
                                    content="Users don’t have an email addresses in profile"
                                />
                            </div>
                        </Row>}
                        {noticeValuesFail.length > 0 && <div className="mb-4 mt-4">
                            {dynamicColumns && <div>
                                {(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                    <Row className="preview-table background-1">
                                        <div className="background-1 preview-table-header">
                                            {showHeaders(templateColumn.manualDynamicColumns)}
                                        </div>
                                        <div className="background-1 preview-table-body">
                                            {showUsers(noticeValuesFail)}
                                        </div>
                                    </Row>}
                            </div>}
                            {!dynamicColumns && <div>
                                {(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                    <Row className="preview-table background-1">
                                        <div className="background-1 preview-table-header">
                                            {showHeaders(templateColumn.manualDynamicColumns)}
                                        </div>
                                    </Row>
                                }{(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                <Row className="preview-table background-1">
                                    <div className="background-1 preview-table-body">
                                        {showUsersFail(noticeValuesFail)}
                                    </div>
                                </Row>
                            }
                            </div>}
                        </div>}

                        {(noticeValues.length > 0 || noticeValuesEmail.length > 0) && <Row className="background-1">
                            <div className="email-information">
                                <span className="description-data">Email Status :  </span>
                                <span className="description-data description-data-green">Sent  </span>

                            </div>
                        </Row>}
                        {noticeValues.length > 0 && <div className="mb-5 mt-4">
                            {dynamicColumns && <div>
                                {(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                    <Row className="preview-table background-1">
                                        <div className="background-1 preview-table-header">
                                            {showHeaders(templateColumn.manualDynamicColumns)}
                                        </div>
                                        <div className="background-1 preview-table-body">
                                            {showUsers(noticeValues)}
                                        </div>
                                    </Row>}
                            </div>}
                            {!dynamicColumns && <div>
                                {(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                    <Row className="preview-table background-1">
                                        <div className="background-1 preview-table-header">
                                            {showHeaders(templateColumn.manualDynamicColumns)}
                                        </div>
                                    </Row>
                                }{(templateColumn.manualDynamicColumns !== '' || templateColumn.manualDynamicColumns !== undefined) &&
                                <Row className="preview-table background-1">
                                    <div className="background-1 preview-table-body">
                                        {showUsersOnly(noticeValues)}
                                    </div>
                                </Row>
                            }
                            </div>}
                        </div>}

                        {noticeValuesEmail.length > 0 && <div className="mb-5 mt-4">
                            {prismColumns && <div>
                                {(combinedColumns !== '' || combinedColumns !== undefined) &&
                                    <Row className="preview-table background-1">
                                        <div className="background-1 preview-table-header">
                                            {showHeadersEmail(combinedColumns)}
                                        </div>
                                        <div className="background-1 preview-table-body">
                                            {showUsersEmail(noticeValuesEmail)}
                                        </div>
                                    </Row>}
                            </div>}
                            {!prismColumns && <div>
                                {(combinedColumns !== '' || combinedColumns !== undefined) &&
                                    <Row className="preview-table background-1">
                                        <div className="background-1 preview-table-header">
                                            {showHeadersEmail(combinedColumns)}
                                        </div>
                                    </Row>
                                }{(combinedColumns !== '' || combinedColumns !== undefined) &&
                                <Row className="preview-table background-1">
                                    <div className="background-1 preview-table-body">
                                        {showUsersOnly(noticeValuesEmail)}
                                    </div>
                                </Row>
                            }
                            </div>}
                        </div>}
                        <Footer/>
                    </Row>
                </div>
            </Row>
        </>
    );
}

export default LettersAcknowledgement;
